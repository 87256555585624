/*!
 * jquery.lightbox.js v1.3
 * https://github.com/duncanmcdougall/Responsive-Lightbox
 * Copyright 2015 Duncan McDougall and other contributors; @license Creative Commons Attribution 2.5
 *
 * Options: 
 * margin - int - default 50. Minimum margin around the image
 * nav - bool - default true. enable navigation
 * blur - bool - default true. Blur other content when open using css filter
 * minSize - int - default 0. Min window width or height to open lightbox. Below threshold will open image in a new tab.
 *
 */
!function (a) {
    "use strict";
    a.fn.lightbox = function (b) {
        var c = {margin: 50, nav: !0, blur: !0, minSize: 0}, d = {
            items: [], lightbox: null, image: null, current: null, locked: !1, caption: null, init: function (b) {
                d.items = b;
                var e = "lightbox-" + Math.floor(1e5 * Math.random() + 1);
                a("body").append('<div id="' + e + '" class="lightbox" style="display:none;"><a href="#" class="lightbox__close lightbox__button"></a><a href="#" class="lightbox__nav lightbox__nav--prev lightbox__button"></a><a href="#" class="lightbox__nav lightbox__nav--next lightbox__button"></a><div href="#" class="lightbox__caption"><p></p></div></div>'), d.lightbox = a("#" + e), d.caption = a(".lightbox__caption", d.lightbox), d.items.length > 1 && c.nav ? a(".lightbox__nav", d.lightbox).show() : a(".lightbox__nav", d.lightbox).hide(), d.bindEvents()
            }, loadImage: function () {
                c.blur && a("body").addClass("blurred"), a("img", d.lightbox).remove(), d.lightbox.fadeIn("fast").append('<span class="lightbox__loading"></span>');
                var b = a('<img src="' + a(d.current).attr("href") + '" draggable="false">');
                a(b).on("load", function () {
                    a(".lightbox__loading").remove(), d.lightbox.append(b), d.image = a("img", d.lightbox).hide(), d.resizeImage(), d.setCaption()
                })
            }, setCaption: function () {
                var b = a(d.current).data("caption");
                b && b.length > 0 ? (d.caption.fadeIn(), a("p", d.caption).text(b)) : d.caption.hide()
            }, resizeImage: function () {
                var b, e, f, g, h;
                e = a(window).height() - c.margin, f = a(window).outerWidth(!0) - c.margin, d.image.width("").height(""), g = d.image.height(), h = d.image.width(), h > f && (b = f / h, h = f, g = Math.round(g * b)), g > e && (b = e / g, g = e, h = Math.round(h * b)), d.image.width(h).height(g).css({
                    top: (a(window).height() - d.image.outerHeight()) / 2 + "px",
                    left: (a(window).width() - d.image.outerWidth()) / 2 + "px"
                }).show(), d.locked = !1
            }, getCurrentIndex: function () {
                return a.inArray(d.current, d.items)
            }, next: function () {
                return d.locked ? !1 : (d.locked = !0, void(d.getCurrentIndex() >= d.items.length - 1 ? a(d.items[0]).click() : a(d.items[d.getCurrentIndex() + 1]).click()))
            }, previous: function () {
                return d.locked ? !1 : (d.locked = !0, void(d.getCurrentIndex() <= 0 ? a(d.items[d.items.length - 1]).click() : a(d.items[d.getCurrentIndex() - 1]).click()))
            }, bindEvents: function () {
                a(d.items).click(function (b) {
                    if (!d.lightbox.is(":visible") && (a(window).width() < c.minSize || a(window).height() < c.minSize)) return void a(this).attr("target", "_blank");
                    var e = a(this)[0];
                    b.preventDefault(), d.current = e, d.loadImage(), a(document).on("keydown", function (a) {
                        27 === a.keyCode && d.close(), 39 === a.keyCode && d.next(), 37 === a.keyCode && d.previous()
                    })
                }), d.lightbox.on("click", function (a) {
                    //dont close when click out image or buttons
                    // this === a.target && d.close()
                }), a(d.lightbox).on("click", ".lightbox__nav--prev", function () {
                    return d.previous(), !1
                }), a(d.lightbox).on("click", ".lightbox__nav--next", function () {
                    return d.next(), !1
                }), a(d.lightbox).on("click", ".lightbox__close", function () {
                    return d.close(), !1
                }), a(window).resize(function () {
                    d.image && d.resizeImage()
                })
            }, close: function () {
                a(document).off("keydown"), a(d.lightbox).fadeOut("fast"), a("body").removeClass("blurred")
            }
        };
        a.extend(c, b), d.init(this)
    }
}(jQuery);