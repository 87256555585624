//=require jquery.min.js

// require what-input.min.js

//=require slick.min.js
//=require lightbox.js

//================ limpopo code ================//

;(function ($) {
    $(document).ready(function () {

        function checkClick(e, elements) {
            if (!elements.is(e.target) // если клик был не по нашему блоку
                && elements.has(e.target).length === 0) { // и не по его дочерним элементам
                elements.removeClass('active'); // скрываем его
            }
        };

        var isMenuOpen = false

        $('.nav-label').on('click', function () {
            isMenuOpen = isMenuOpen === true ? false : true;
            if (isMenuOpen) {
                $('body').addClass('overflow');
            } else {
                $('body').removeClass('overflow');
            }
        });

        $(document).on('click', function (e) {
            let target = $(e.target)
            if (isMenuOpen && !$('.nav').is(e.target) && $('.nav').has(e.target).length === 0 && !$('.nav-label').is(e.target) && $('.nav-label').has(e.target).length === 0) {
                $('body').removeClass('overflow');
                isMenuOpen = false;
            }
        })

        $('#overlay').on('click', function(){
            if (isMenuOpen){
                $('body').removeClass('overflow');
                isMenuOpen = false;
            }
        })

        let userBtn = $('#userAvatar'),
            body = $('body');

        userBtn.on('click', function () {
            $(this).closest('.user').toggleClass('active');
        })

        $(document).on('click', function (e) {
            checkClick(e, $('.user'));
        });

        let collapsedItem = $('.collapsed-item'),
            collapsedItemTitle = $('.collapsed-item__title');

        collapsedItemTitle.on('click', function (e) {
            let self = $(this).closest('.collapsed-item');
            if (self.hasClass('active')) {
                self.removeClass('active');
                $(this).closest('.collapsed-wrapper').removeClass('hasActive');
            } else {
                collapsedItem.removeClass('active');
                $(this).closest('.collapsed-wrapper').addClass('hasActive');
                self.addClass('active');
            }

        })

        let filterItem = $('.filter-item__btn'),
            filterContent = $('.filter-content');

        filterItem.on('click', function (e) {
            let selfContent = $(this).attr('href'),
                self = $(this).closest('.filter-item');

            $('.filter-item').removeClass('active teachers-filter__item--active');
            filterContent.removeClass('active');

            self.addClass('active teachers-filter__item--active');
            $(selfContent).addClass('active');
            $('html, body').animate({
                scrollTop: $(selfContent).offset().top - 90
            }, 1000);
        })

//=============== slider ===============//
//       $('.gallery-slider').slick({
//           dots: true,
//           infinite: true,
//           speed: 300,
//           slidesToShow: 1,
//           adaptiveHeight: true
//       });
//=============== slider ===============//


        // =========== gallery ligtbox ==========//
        $(function () {
            $('.photo__item').lightbox();
        });

        $('.lightbox').off('click');
        // =========== gallery ligtbox ==========//

        $('.rating-review input').on('change', function () {
            $('#review_count_stars').val($(this).val());
            $('#reviews-star-mark').text($(this).val() + ',0');
        });

        $('.reviews-more').on('click', function(){
            if ($(this).prev('.reviews__list').length){
                $(this).prev('.reviews__list').find('.hidden').removeClass('hidden');
            }
            $(this).closest('.filter-content').find('.hidden').removeClass('hidden');
            const $target = $(this).closest('.active');

            $('html, body').animate({
                scrollTop: $target.offset().top
            }, 400);
            $(this).remove();
        })

        $('.collapsed-item__title').on('click', function() {

            const $target = $(this).closest('.collapsed-item');

            $target.toggleClass('b--collapsed');

            $('html, body').animate({
                scrollTop: $target.offset().top
            }, 400);

        })

    })
})(jQuery);
